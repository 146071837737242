// import React from 'react';
import { demoPages, mainMenu, administrationMenu, libraryMenu } from '../menu';
// import Footer from '../layout/Footer/Footer';

const footers = [
	{ path: mainMenu.tasks.path, element: null, exact: true },
	{ path: `${mainMenu.tasks.path}/new`, element: null, exact: true },
	{ path: `${mainMenu.tasks.path}/:hash/files/:file`, element: null, exact: true },
	{ path: mainMenu.dashboard.path, element: null, exact: true },
	{ path: mainMenu.book.path, element: null, exact: true },
	{ path: mainMenu.projects.path, element: null, exact: true },
	{ path: `${mainMenu.projects.path}/:project`, element: null, exact: true },
	{ path: `${mainMenu.projects.path}/:project/files`, element: null, exact: true },
	{ path: `${mainMenu.projects.path}/:project/files/:hash`, element: null, exact: true },
	{ path: `${mainMenu.projects.path}/:project/files/:hash/:file`, element: null, exact: true },
	{ path: `${mainMenu.projects.path}/:project/files/:hash/:file/view`, element: null, exact: true },
	{ path: `${mainMenu.projects.path}/:project/documents`, element: null, exact: true },
	{ path: `${mainMenu.projects.path}/:project/documents/:hash`, element: null, exact: true },
	{ path: `${mainMenu.projects.path}/:project/documents/:hash/:file`, element: null, exact: true },
	{
		path: `${mainMenu.projects.path}/:project/documents/:hash/:file/view`,
		element: null,
		exact: true,
	},
	{ path: `/share/:key/${mainMenu.projects.path}`, element: null, exact: true },
	{ path: `/share/:key/${mainMenu.projects.path}/:project`, element: null, exact: true },
	{ path: `/share/:key${mainMenu.projects.path}/:project/:hash`, element: null, exact: true },
	{ path: `/share/:key${mainMenu.projects.path}/:project/:hash/view`, element: null, exact: true },
	{ path: mainMenu.agreements.path, element: null, exact: true },
	{ path: `${mainMenu.agreements.path}/:hash`, element: null, exact: true },
	{ path: `${mainMenu.agreements.path}/:hash/files/:file`, element: null, exact: true },
	{ path: mainMenu.documents.subMenu.contractors.path, element: null, exact: true },
	{ path: `${mainMenu.documents.subMenu.contractors.path}/:inn`, element: null, exact: true },
	{ path: mainMenu.documents.subMenu.docs.path, element: null, exact: true },
	{ path: `${mainMenu.documents.subMenu.docs.path}/:category`, element: null, exact: true },
	{
		path: `${mainMenu.documents.subMenu.docs.path}/:category/:document`,
		element: null,
		exact: true,
	},
	{
		path: mainMenu.inventarization.path,
		element: null,
		exact: true,
	},
	{
		path: mainMenu.fleet.path,
		element: null,
		exact: true,
	},
	{
		path: `${mainMenu.fleet.path}/vehicles/:hash`,
		element: null,
		exact: true,
	},
	{
		path: `${mainMenu.fleet.path}/fines/:hash`,
		element: null,
		exact: true,
	},
	{
		path: mainMenu.reports.subMenu.worktime.path,
		element: null,
		exact: true,
	},
	{
		path: libraryMenu.library.subMenu.places.path,
		element: null,
		exact: true,
	},
	{
		path: administrationMenu.administration.subMenu.administrationUsers.path,
		element: null,
		exact: true,
	},
	{
		path: `${administrationMenu.administration.subMenu.administrationUsers.path}/:hash/*`,
		element: null,
		exact: true,
	},
	{
		path: administrationMenu.administration.subMenu.administrationApi.path,
		element: null,
		exact: true,
	},
	{ path: 'profile', element: null, exact: true },
	// { path: layoutMenu.blank.path, element: <Footer />, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: `${demoPages.signUp.path}/:hash`, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	// { path: demoPages.knowledge.subMenu.grid.path, element: null, exact: true },
	{ path: '*', element: null },
];

export default footers;
