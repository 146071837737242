import React from 'react';
import { demoPages, mainMenu, administrationMenu, libraryMenu } from '../menu';
// import DashboardHeader from '../pages/common/Headers/DashboardHeader';
// import DashboardBookingHeader from '../pages/common/Headers/DashboardBookingHeader';
// import ProfilePageHeader from '../pages/common/Headers/ProfilePageHeader';
// import SummaryHeader from '../pages/common/Headers/SummaryHeader';
// import ProductsHeader from '../pages/common/Headers/ProductsHeader';
// import ProductListHeader from '../pages/common/Headers/ProductListHeader';
// import PageLayoutHeader from '../pages/common/Headers/PageLayoutHeader';
// import ComponentsHeader from '../pages/common/Headers/ComponentsHeader';
// import FormHeader from '../pages/common/Headers/FormHeader';
// import ChartsHeader from '../pages/common/Headers/ChartsHeader';
// import ContentHeader from '../pages/common/Headers/ContentHeader';
// import UtilitiesHeader from '../pages/common/Headers/UtilitiesHeader';
// import IconHeader from '../pages/common/Headers/IconHeader';
// import ExtrasHeader from '../pages/common/Headers/ExtrasHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';
import SearchHeader from '../pages/common/Headers/SearchHeader';
import GuestHeader from '../pages/common/Headers/GuestHeader';

const headers = [
	{ path: mainMenu.tasks.path, element: <DefaultHeader />, exact: true },
	{ path: `${mainMenu.tasks.path}/new`, element: <DefaultHeader />, exact: true },
	{ path: `${mainMenu.tasks.path}/:id`, element: <DefaultHeader />, exact: true },
	{
		path: `${mainMenu.tasks.path}/:hash/files/:file`,
		element: <DefaultHeader />,
		exact: true,
	},
	{ path: mainMenu.dashboard.path, element: <DefaultHeader />, exact: true },
	{ path: mainMenu.book.path, element: <SearchHeader />, exact: true },
	{ path: mainMenu.projects.path, element: <SearchHeader searchComponent='modal' />, exact: true },
	{ path: `${mainMenu.projects.path}/:project`, element: <DefaultHeader />, exact: true },
	{ path: `${mainMenu.projects.path}/:project/files`, element: <DefaultHeader />, exact: true },
	{
		path: `${mainMenu.projects.path}/:project/files/:hash`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/files/:hash/:file`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/files/:hash/:file/view`,
		element: <DefaultHeader />,
		exact: true,
	},
	{ path: `${mainMenu.projects.path}/:project/documents`, element: <DefaultHeader />, exact: true },
	{
		path: `${mainMenu.projects.path}/:project/documents/:hash`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/documents/:hash/:file`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/documents/:hash/:file/view`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `/share/:key/${mainMenu.projects.path}`,
		element: <GuestHeader />,
		exact: true,
	},
	{
		path: `/share/:key/${mainMenu.projects.path}/:project`,
		element: <GuestHeader />,
		exact: true,
	},
	{
		path: `/share/:key/${mainMenu.projects.path}/:project/:hash`,
		element: <GuestHeader />,
		exact: true,
	},
	{
		path: `/share/:key/${mainMenu.projects.path}/:project/:hash/view`,
		element: <GuestHeader />,
		exact: true,
	},
	{
		path: mainMenu.agreements.path,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.agreements.path}/:hash`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.agreements.path}/:hash/files/:file`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: mainMenu.documents.subMenu.contractors.path,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.documents.subMenu.contractors.path}/:inn`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: mainMenu.documents.subMenu.docs.path,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.documents.subMenu.docs.path}/:category`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.documents.subMenu.docs.path}/:category/:document`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: mainMenu.inventarization.path,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: mainMenu.fleet.path,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.fleet.path}/vehicles/:hash`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${mainMenu.fleet.path}/fines/:hash`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: mainMenu.reports.subMenu.worktime.path,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: libraryMenu.library.subMenu.places.path,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: administrationMenu.administration.subMenu.administrationUsers.path,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: `${administrationMenu.administration.subMenu.administrationUsers.path}/:hash/*`,
		element: <DefaultHeader />,
		exact: true,
	},
	{
		path: administrationMenu.administration.subMenu.administrationApi.path,
		element: <DefaultHeader />,
		exact: true,
	},
	{ path: 'profile', element: <DefaultHeader />, exact: true },
	// { path: layoutMenu.pageLayout.subMenu.onlySubheader.path, element: null, exact: true },
	// { path: layoutMenu.pageLayout.subMenu.onlyContent.path, element: null, exact: true },
	// { path: layoutMenu.blank.path, element: <DefaultHeader />, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: `${demoPages.signUp.path}/:hash`, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	// { path: demoPages.knowledge.subMenu.grid.path, element: null, exact: true },
	// { path: dashboardMenu.dashboard.path, element: <DashboardHeader />, exact: true },
	// {
	// 	path: demoPages.projectManagement.subMenu.list.path,
	// 	element: <DashboardHeader />,
	// 	exact: true,
	// },
	// { path: demoPages.pricingTable.path, element: <DashboardHeader />, exact: true },
	// {
	// 	path: dashboardMenu.dashboardBooking.path,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.appointment.subMenu.calendar.path,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.appointment.subMenu.employeeList.path,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.listPages.subMenu.listFluid.path,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.editPages.path}/*`,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.appointment.subMenu.employeeID.path}/*`,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.projectManagement.subMenu.itemID.path}/*`,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.singlePages.subMenu.fluidSingle.path,
	// 	element: <ProfilePageHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.singlePages.subMenu.boxedSingle.path,
	// 	element: <ProfilePageHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.sales.subMenu.transactions.path,
	// 	element: <ProfilePageHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.chat.subMenu.withListChat.path,
	// 	element: <ProfilePageHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.chat.subMenu.onlyListChat.path,
	// 	element: <ProfilePageHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.knowledge.subMenu.itemID.path}/:id`,
	// 	element: <ProfilePageHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.crm.subMenu.dashboard.path,
	// 	element: <ProfilePageHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.crm.subMenu.customersList.path,
	// 	element: <ProfilePageHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.crm.subMenu.customerID.path}/:id`,
	// 	element: <ProfilePageHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.summary.path,
	// 	element: <SummaryHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.gridPages.subMenu.gridBoxed.path,
	// 	element: <ProductsHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.gridPages.subMenu.gridFluid.path,
	// 	element: <ProductsHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.listPages.subMenu.listBoxed.path,
	// 	element: <ProductListHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.sales.subMenu.salesList.path,
	// 	element: <ProductListHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.sales.subMenu.productsGrid.path,
	// 	element: <ProductListHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.sales.subMenu.productID.path}/:id`,
	// 	element: <ProductListHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: `${layoutMenu.asideTypes.path}/*`,
	// 	element: <PageLayoutHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
	// 	element: <PageLayoutHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
	// 	element: <PageLayoutHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: `${componentsMenu.components.path}/*`,
	// 	element: <ComponentsHeader />,
	// },
	// {
	// 	path: `${componentsMenu.forms.path}/*`,
	// 	element: <FormHeader />,
	// },
	// {
	// 	path: `${componentsMenu.charts.path}/*`,
	// 	element: <ChartsHeader />,
	// },
	// {
	// 	path: `${componentsMenu.content.path}/*`,
	// 	element: <ContentHeader />,
	// },
	// {
	// 	path: `${componentsMenu.utilities.path}/*`,
	// 	element: <UtilitiesHeader />,
	// },
	// {
	// 	path: `${componentsMenu.icons.path}/*`,
	// 	element: <IconHeader />,
	// },
	// {
	// 	path: `${componentsMenu.extra.path}/*`,
	// 	element: <ExtrasHeader />,
	// },
	{
		path: `*`,
		element: null,
	},
];

export default headers;
